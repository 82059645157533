<template>
  <div>
    <PMessage v-if="!relationInfo">
      {{ $t("field_relationship_not_setup") }}
    </PMessage>

    <template v-else>
      <TreeSelect
        v-if="isRelatedToTreeView"
        :options="treeNodes"
        :modelValue="modelValue"
        display="chip"
        selectionMode="checkbox"
        :loading="isRelatedItemsLoading"
        :placeholder="isRelatedItemsLoading ? $t('items_is_loading') : ''"
        :tabindex="props.column.fieldInfo.meta.sortPosition"
        :aria-label="getFieldLabel(props.column.fieldInfo)"
        :emptyMessage="$t('field_select_empty_items')"
        :class="['w-full']"
        inputClass="form-select sm:mt-2 sm:mr-2"
        @update:modelValue="onUpdateModelValue"
        v-on="dropdownEventListeners"
      >
        <template #value="slotProps">
          <template v-if="isArray(slotProps.value)">
            <div
              v-for="node of slotProps.value"
              :key="node.key"
              class="p-treeselect-token"
            >
              <span class="p-treeselect-token-label">
                <RenderTemplate
                  v-if="!!relatedItemsHashMap[node.data] && !!node.data"
                  :collectionName="relationInfo.relatedCollection ?? ''"
                  :item="relatedItemsHashMap[node.data]"
                  :fieldInfo="props.column.fieldInfo"
                  :defaultTemplate="relatedRenderTemplate ?? ''"
                ></RenderTemplate>

                <template v-else>
                  {{ node.data }}
                </template>
              </span>
            </div>
          </template>

          <template v-else>
            {{ slotProps.value }}
          </template>
        </template>
      </TreeSelect>

      <MultiSelect
        v-else
        optionValue="value"
        optionLabel="label"
        dataKey="value"
        :options="stableOptions"
        :virtualScrollerOptions="virtualScrollerOptions"
        :editable="false"
        :emptySelectionMessage="$t('field_select_empty_selection')"
        :emptyFilterMessage="$t('field_select_filter_empty_items')"
        :emptyMessage="$t('field_select_empty_items')"
        :tabindex="props.column.fieldInfo.meta.sortPosition"
        :aria-label="getFieldLabel(props.column.fieldInfo)"
        :class="['w-full']"
        inputClass="form-select"
        :modelValue="modelValue"
        display="chip"
        :loading="isRelatedItemsLoading"
        :placeholder="isRelatedItemsLoading ? $t('items_is_loading') : ''"
        :disabled="isRelatedItemsLoading || props.column.fieldInfo.meta.isReadonly"
        @update:modelValue="onUpdateModelValue"
        v-on="dropdownEventListeners"
      >
        <template #header>
          <div class="p-dropdown-header">
            <div class="p-dropdown-filter-container">
              <input
                type="text"
                autocomplete="off"
                role="search"
                :value="search"
                :class="['p-dropdown-filter', 'p-inputtext', 'p-component']"
                v-on="searchEventListeners"
              />

              <span :class="['p-dropdown-filter-icon', 'pi pi-search']" />
            </div>
          </div>
        </template>

        <template #chip="slotProps">
          <RenderTemplate
            v-if="!!relatedItemsHashMap[slotProps.value] && !!slotProps.value"
            :collectionName="relationInfo.relatedCollection ?? ''"
            :item="relatedItemsHashMap[slotProps.value]"
            :fieldInfo="props.column.fieldInfo"
            :defaultTemplate="relatedRenderTemplate ?? ''"
          ></RenderTemplate>

          <template v-else>
            {{ slotProps.value }}
          </template>
        </template>

        <template #option="slotProps">
          <RenderTemplate
            v-if="!!relatedItemsHashMap[slotProps.option.value]"
            :collectionName="relationInfo.relatedCollection ?? ''"
            :item="relatedItemsHashMap[slotProps.option.value]"
            :fieldInfo="props.column.fieldInfo"
            :defaultTemplate="relatedRenderTemplate ?? ''"
          ></RenderTemplate>

          <template v-else>
            {{ slotProps.option.label }}
          </template>
        </template>
      </MultiSelect>
    </template>
  </div>
</template>

<script setup lang="ts">
interface _VTI_TYPE_DataTableFilterMetaData {
    /**
     * Filter value
     */
    value: any;
    /**
     * Filter match mode
     */
    matchMode: 'startsWith' | 'contains' | 'notContains' | 'endsWith' | 'equals' | 'notEquals' | 'in' | 'lt' | 'lte' | 'gt' | 'gte' | 'between' | 'dateIs' | 'dateIsNot' | 'dateBefore' | 'dateAfter' | string | undefined;
}
type _VTI_TYPE_Column = {
  name: string;
  fieldInfo: FieldInfoInterface;
}
interface ColumnFilterEmits {
  (e: "update:filter", payload: UpdateFilterEmitPayload): void;
}
interface ColumnFilterProps {
  collectionName: string;
  column: _VTI_TYPE_Column;
  filter: _VTI_TYPE_DataTableFilterMetaData;
  matchMode?: FilterMatchModeOptions[keyof FilterMatchModeOptions];
}
  import { computed, ref, unref, watchEffect } from 'vue'
  import isArray from 'lodash/isArray'
  import { DataTableFilterMetaData } from 'primevue/datatable'
  import PMessage from 'primevue/message'
  import TreeSelect from 'primevue/treeselect'
  import { TreeNode } from 'primevue/tree'
  import { useCollecitonsStore } from '~/stores/collections'
  import { useRelationsStore } from '~/stores/relations'
  import { useRelationM2M } from '~/api/relations/composables/useRelationM2M'
  import ItemInterface from '~/api/items/entities/ItemInterface'
  import CollectionInterface from '~/api/collections/entities/CollectionInterface'
  import RelationInterface from '~/api/relations/entity/RelationInterface'
  import { getFieldLabel } from '~/entities/field'
  import { createTextFromDisplayTemplate } from '~/entities/render-template'
  import { useDropdownItemsController } from '~/service/dropdown-items/composables/useDropdownItemsController'
  import { TreeNodeCheckboxData } from '~/service/treeview/types'
  import { defineTreeNode, defineTreeNodeCheckboxData } from '~/service/treeview/defines'
  import { RenderTemplate } from '~/entities/render-template'
  import MultiSelect from '~/components/MultiSelect/MultiSelect.vue'
  import { ColumnFilterMeta } from '../../lib/interfaces'
  const props = defineProps<ColumnFilterProps>();
  const emit = defineEmits<ColumnFilterEmits>();
  type TreeModelValue = {
    [key: string | number]: { checked: boolean; partialChecked: boolean };
  };
  type LinearModelValue = (string | number)[];
  const { relationInfo, relatedCollection } = useRelationM2M(
    computed(() => props.collectionName),
    computed(() => props.column.fieldInfo),
  );
  const filterField = computed<string>(() => {
    const junctionFieldName = relationInfo.value?.fieldName;
    const relatedPK = relatedCollection.value?.getPrimaryFieldInfo()?.name;
    if (!junctionFieldName || !relatedPK) return "";
    return `${props.column.fieldInfo.name}.${junctionFieldName}`;
  });
  const handleTreeChange = (event: TreeModelValue): LinearModelValue => {
    const result: LinearModelValue = [];
    for (const itemId in event) {
      result.push(itemId);
    }
    return result;
  };
  const onUpdateModelValue = (event: LinearModelValue | TreeModelValue): void => {
    const newFilterData = event instanceof Array ? event : handleTreeChange(event);
    emit("update:filter", {
      filterName: props.column.name,
      data: newFilterData,
      filterMeta: {
        [ColumnFilterMeta.API_FILTER_NAME]: unref(filterField),
      },
      immediate: true,
    });
  };
  const {
    items: relatedCollectionItems,
    options: stableOptions,
    search,
    isLoading: isRelatedItemsLoading,
    virtualScrollerOptions,
    dropdownEventListeners,
    searchEventListeners,
  } = useDropdownItemsController(
    computed(() => relatedCollection.value),
    computed(() => props.column.fieldInfo),
  );
  const relatedItemsHashMap = computed<Record<ItemInterface["id"], ItemInterface>>(() =>
    relatedCollectionItems.value.reduce<Record<ItemInterface["id"], ItemInterface>>(
      (hashMap, item) => {
        if (item.id in hashMap) return hashMap;
        hashMap[item.id] = item;
        return hashMap;
      },
      {},
    ),
  );
  const collectionsStore = useCollecitonsStore();
  const collection = ref<CollectionInterface | undefined>();
  watchEffect(() => {
    collection.value = collectionsStore.getCollection(props.collectionName);
  });
  const relatedRenderTemplate = computed(
    () =>
      relatedCollection.value?.meta?.displayTemplate ||
      collection.value?.meta.displayTemplate ||
      `{{${collection.value?.getPrimaryFieldInfo()?.name}}}`,
  );
  /**
   *
   * @note TreeSelect logic
   *
   */
  const relationsStore = useRelationsStore();
  const relationWithPotentialTreeView = computed<RelationInterface | undefined>(() =>
    relationsStore.relations.find((relation) => {
      return (
        relation.collectionName === relatedCollection.value?.id &&
        relation.relatedFieldName === relationInfo.value?.relatedFieldName
      );
    }),
  );
  const isRelatedToTreeView = computed<boolean>(() => {
    if (!relatedCollection.value) return false;
    const relationToPotentialTreeView = relationWithPotentialTreeView.value;
    if (!relationToPotentialTreeView) return false;
    const potentialTreeViewField = relatedCollection.value.fieldsInfo.find(
      (fieldInfo) => fieldInfo.name === relationToPotentialTreeView.meta?.oneField,
    );
    return potentialTreeViewField?.meta.interface === "list-o2m-tree-view";
  });
  /**
   * @note Non optimized algorithm. Excessive consumption of memory
   */
  const treeNodes = computed<TreeNode[]>(() => {
    if (!relatedCollectionItems.value || !relatedCollection.value) return [];
    const childrensField = relatedCollection.value.fieldsInfo.find(
      (fieldInfo) => fieldInfo.meta.interface === "list-o2m-tree-view",
    );
    if (!childrensField) return [];
    const childrenDataExpression = `${childrensField.name}.currentJunctionItemIds`;
    const parentFieldName = relationWithPotentialTreeView.value?.fieldName ?? undefined;
    if (parentFieldName === undefined) return [];
    const parentField = relatedCollection.value.fieldsInfo.find(
      (fieldInfo) => fieldInfo.name === parentFieldName,
    );
    if (!parentField) return [];
    const items = relatedCollectionItems.value;
    const itemsAsTreeNodesMap: { [key: string]: TreeNode } = {};
    for (const item of items) {
      const label =
        createTextFromDisplayTemplate(item, relatedRenderTemplate.value) ?? item.id;
      itemsAsTreeNodesMap[item.id] = defineTreeNode({
        key: item.id,
        label,
        data: item.id,
        children: [],
        meta: {
          parentId: item.getFieldDataByExpression(parentField.name),
          childrenIds: item.getFieldDataByExpression(childrenDataExpression),
        },
      });
    }
    const itemsTreeNodes = Object.values(itemsAsTreeNodesMap);
    for (let i = 0; i < itemsTreeNodes.length; i++) {
      const currentItem = itemsTreeNodes[i];
      const parentId = currentItem.meta.parentId;
      if (parentId === undefined) continue;
      itemsAsTreeNodesMap[parentId].children?.push(currentItem);
    }
    return itemsTreeNodes.filter((treeNode) => {
      return treeNode.meta.parentId === undefined;
    });
  });
  const transformFilterToTreeViewValue = (
    filter: DataTableFilterMetaData,
  ): TreeModelValue => {
    if (!filter.value) return {};
    const result: Record<string, TreeNodeCheckboxData> = {};
    for (const modelValue of filter.value) {
      result[modelValue] = defineTreeNodeCheckboxData({
        checked: true,
        partialChecked: false,
      });
    }
    return result;
  };
  const transformFilterToMultiselectValue = (
    filter: DataTableFilterMetaData,
  ): LinearModelValue => {
    if (!filter.value) return [];
    return filter.value;
  };
  const modelValue = computed<LinearModelValue | TreeModelValue>(() =>
    isRelatedToTreeView.value
      ? transformFilterToTreeViewValue(props.filter)
      : transformFilterToMultiselectValue(props.filter),
  );
</script>

<style scoped></style>

